import useJwt from '@/auth/jwt/useJwt';

export const isUserLoggedIn = () => {
  return (
    localStorage.getItem('userData') &&
    localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  );
};

export const getUserData = () => JSON.parse(localStorage.getItem('userData'));
export const getServidorData = () =>
  JSON.parse(localStorage.getItem('servidorSelected'));
export const getGrupoAcesso = () =>
  JSON.parse(localStorage.getItem('userData')).grupoAcesso;

export const hasPermissao = (perm, grupoAcesso) => {
  try {
    if (!grupoAcesso) {
      grupoAcesso = JSON.parse(localStorage.getItem('userData'))?.grupoAcesso;
    }

    const permissoes = grupoAcesso?.grupoAcessoPermissoes;

    if (!permissoes) {
      return false;
    }

    const hasPerm = permissoes?.filter(item => item.chave === perm).length > 0;

    return hasPerm;
  } catch (error) {
    console.error('[hasPermissao]', error);
    return false;
  }
};

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'master') return '/'; //Agenda
  if (userRole === 'admin') return '/'; //Adiministrador do Instituto
  if (userRole === 'user') return '/'; //Usuário do Instituto
  return { name: 'auth-login' };
};

<template>
  <v-select v-bind="$attrs" v-on="$listeners">
    <template #no-options>
      <div class="my-no-options-message">
        Desculpe, nenhuma opção encontrada.
      </div>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: 'VSelectPT',
  components: {
    vSelect,
  },
  inheritAttrs: false,
};
</script>

<style lang="scss"></style>

export default [
  {
    path: '/cadastro/aliquotas',
    name: 'CadastroAliquotas',
    component: () => import('@/views/pages/cadastro/aliquotas/aliquotas.vue'),
    meta: {
      pageTitle: 'Alíquotas',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/cadastro/servidor',
    name: 'CadastroServidor',
    component: () => import('@/views/pages/cadastro/servidor.vue'),
    meta: {
      pageTitle: 'Servidor',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/cadastro/unidades',
    name: 'CadastroUnidades',
    component: () => import('@/views/pages/cadastro/unidades/unidades.vue'),
    meta: {
      pageTitle: 'Unidades',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/cadastro/usuario',
    name: 'CadastroUsuario',
    component: () => import('@/views/pages/cadastro/usuario.vue'),
    meta: {
      pageTitle: 'Usuário',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/cadastro/cargos',
    name: 'CadastroCargos',
    component: () => import('@/views/pages/cadastro/cargos/cargos.vue'),
    meta: {
      pageTitle: 'Cargos',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/auditoria',
    name: 'Auditoria',
    component: () => import('@/views/pages/auditoria/auditoria.vue'),
    meta: {
      pageTitle: 'Auditoria dos Cadastros dos Servidores',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/cadastro/instituto',
    name: 'CadastroInstituto',
    component: () => import('@/views/pages/cadastro/instituto.vue'),
    meta: {
      pageTitle: 'Cadastro de Institutos',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/cadastro/orgaos',
    name: 'CadastroOrgaos',
    component: () => import('@/views/pages/cadastro/orgaos/orgaos.vue'),
    meta: {
      pageTitle: 'Órgãos',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/cadastro/financeiro',
    name: 'CadastroFinanceiro',
    component: () => import('@/views/pages/cadastro/financeiro/financeiro.vue'),
    meta: {
      pageTitle: 'Financeiro',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  // {
  //   path: "/auditoria",
  //   name: "AuditoriaProvaVida",
  //   component: () => import("@/views/pages/auditoria_prova_vida/auditoriaProvaDeVida.vue"),
  //   meta: {
  //     pageTitle: "Auditoria das Provas de Vida",
  //     requiresAuth: true,
  //   },
  // },

  {
    path: '/cadastro/lotacoes',
    name: 'CadastroLotacoes',
    component: () => import('@/views/pages/cadastro/lotacoes/lotacoes.vue'),
    meta: {
      pageTitle: 'Lotações',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/selecionaServidor',
    name: 'selecionaServidor',
    component: () => import('@/views/components/selecionaServidor.vue'),
    meta: {
      // pageTitle: "Formulário de cadastro de servidor",
      requiresAuth: true,
      layout: 'full',
    },
  },
  {
    path: '/cadastro/servidor/provaDeVida',
    name: 'provaDeVida',
    component: () => import('@templates/AbaProvavida.vue'),
    meta: {
      // pageTitle: "Formulário da Prova de Vida",
      requiresAuth: false,
      layout: 'full',
    },
  },
  {
    path: '/importarExportar', //PRECISA USAR ESSE DATA.ID
    name: 'ImportarExportar',
    component: () =>
      import('@/views/pages/importarExportar/importarExportar.vue'),
    meta: {
      pageTitle: 'Importação',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/envioSisprev', //PRECISA USAR ESSE DATA.ID
    name: 'EnvioSISPREV',
    component: () => import('@/views/pages/envioSisprev/envioSisprev.vue'),
    meta: {
      pageTitle: 'Fila de envio para o SISPREV',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/reprovadoRejeitado',
    name: 'ReprovadosRejeitados',
    component: () =>
      import('@/views/pages/reprovadoRejeitado/reprovadoRejeitado.vue'),
    meta: {
      pageTitle: 'Controle de Pendências',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/relatorio', //PRECISA USAR ESSE DATA.ID
    name: 'Relatorios',
    component: () => import('@/views/pages/relatorio/relatorio.vue'),
    meta: {
      pageTitle: 'Relatórios',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/configuracaoSistema', //PRECISA USAR ESSE DATA.ID
    name: 'ConfiguracoesSistema',
    component: () =>
      import('@/views/pages/config/configSistema/configSistema.vue'),
    meta: {
      pageTitle: 'Configurações do Sistema',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/configuracaoParametrizacao', //PRECISA USAR ESSE DATA.ID
    name: 'ConfiguracoesParametrizacao',
    component: () => import('@/views/pages/config/configParam/configParam.vue'),
    meta: {
      pageTitle: 'Parametrizações',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/configuracaoWorkflow', //PRECISA USAR ESSE DATA.ID
    name: 'ConfiguracoesWorkflow',
    component: () =>
      import('@/views/pages/config/configWorkflow/configWorkflow.vue'),
    meta: {
      pageTitle: 'Configuração do Workflow - Grupos de Acesso',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/ConfiguracoesBloqueioGeografico',
    name: 'ConfiguracoesBloqueioGeografico',
    component: () =>
      import(
        '@/views/pages/config/configBloqueioGeografico/configBloqueioGeografico.vue'
      ),
    meta: {
      pageTitle: 'Bloqueio Geográfico',
      requiresAuth: true,
    },
  },
  {
    path: '/ConfiguracoesFilaTrabalho',
    name: 'ConfiguracoesFilaTrabalho',
    component: () =>
      import('@/views/pages/config/filaTrabalho/filaTrabalho.vue'),
    meta: {
      pageTitle: 'Fila de Trabalho',
      requiresAuth: true,
    },
  },
  {
    path: '/conta-config/configuracaoConta', //PRECISA USAR ESSE DATA.ID
    name: 'ConfiguracoesPerfil',
    component: () => import('@/views/pages/conta-config/configuracaoConta.vue'),
    meta: {
      pageTitle: 'Alterar dados do perfil',
      requiresAuth: true,
    },
  },
  {
    path: '/help/centralapoio', //PRECISA USAR ESSE DATA.ID
    name: 'centralapoio',
    component: () => import('@/views/pages/help/centralapoio.vue'),
    meta: {
      pageTitle: 'Central de apoio',
      requiresAuth: true,
    },
  },
  {
    path: '/auth/esqueceuSenha',
    name: 'esqueci-a-senha',
    component: () => import('@/views/pages/auth/esqueceuSenha.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      requiresAuth: false,
    },
  },
  {
    path: '/auth/esqueceuSenhaServidor',
    name: 'esqueceuSenhaServidor',
    component: () => import('@/views/pages/auth/servidorEsqueceuSenha.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      requiresAuth: false,
    },
  },
  {
    path: '/autentica-senha',
    name: 'autentica-a-senha',
    component: () => import('@/views/pages/auth/autenticaSenha.vue'),
    meta: {
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/configuracaoOuvidoria',
    name: 'ConfiguracoesOuvidoria',
    component: () =>
      import('@/views/pages/config/configOuvidoria/ouvidoria.vue'),
    meta: {
      pageTitle: 'Ouvidoria',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/configuracaoQuestionario',
    name: 'ConfiguracoesQuestionario',
    component: () =>
      import('@/views/pages/config/configQuestionario/configQuestionario.vue'),
    meta: {
      pageTitle: 'Questionário Socioeconômico',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/configuracoesSitemaLog',
    name: 'ConfiguracoesSitemaLog',
    component: () =>
      import(
        '@/views/pages/config/configSistema/configLog/configSistemaLog.vue'
      ),
    meta: {
      pageTitle: 'Gerenciamento de LOG',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/configuracaoAgendamento',
    name: 'ConfiguracoesAgendamento',
    component: () =>
      import('@/views/pages/config/configAgendamento/configAgendamento.vue'),
    meta: {
      pageTitle: 'Configuração dos polos',
      requiresAuth: true,
      requiresParametrizacao: true,
    },
  },
  {
    path: '/cadastro/servidor',
    name: 'PresencialInlocoCadastro',
    component: () => import('@/views/pages/cadastro/servidor.vue'),
    meta: {
      pageTitle: 'Servidor',
      requiresAuth: true,
      requiresParametrizacao: false,
    },
  },
  {
    path: '/importarParametrizacoes',
    name: 'PresencialInlocoImportarParametrizacoes',
    component: () => import('@/views/components/importarParametrizacoes.vue'),
    meta: {
      pageTitle: 'Importação / Parametrizações',
      requiresAuth: true,
    },
  },
  {
    path: '/agendamento',
    name: 'PresencialInlocoAgendamento',
    component: () =>
      import(
        '@/views/pages/relatorio/relatorioAgendamento/relatorioAgendamento.vue'
      ),
    meta: {
      pageTitle: 'Agendamento(s)',
      requiresAuth: true,
    },
  },
  {
    path: '/integracaoDiaria',
    name: 'RecebimentosInLoco',
    component: () =>
      import('@/views/pages/integracaoDiaria/integracaoDiaria.vue'),
    meta: {
      pageTitle: 'Recebimentos In Loco',
      requiresAuth: true,
    },
  },
  {
    path: '/atendimentoDomiciliar',
    name: 'AtendimentoDomiciliar',
    component: () =>
      import('@/views/pages/atendimentoDomiciliar/atendimentoDomiciliar.vue'),
    meta: {
      pageTitle: 'Atendimento Domiciliar',
      requiresAuth: true,
    },
  },
  {
    path: '/triagem',
    name: 'PresencialInlocoTriagem',
    component: () => import('@/views/pages/triagem/triagem.vue'),
    meta: {
      pageTitle: 'Triagem',
      requiresAuth: true,
    },
  },
  {
    path: '/triagem-servidor',
    name: 'Triagem Servidor',
    component: () => import('@/views/pages/triagem/triagemServidor.vue'),
    meta: {
      pageTitle: 'Triagem Servidor',
      requiresAuth: true,
    },
  },
];

import Vue from 'vue';
import VueRouter from 'vue-router';

import { canNavigate } from '@/libs/acl/routeProtection';
import {
  isUserLoggedIn,
  getUserData,
  getGrupoAcesso,
  getHomeRouteForLoggedInUser,
  hasPermissao,
} from '@/auth/utils';
import pages from './routes/pages';
import { useLoadingStore } from '@/store/pinia/loadingStore';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...pages,
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/pages/auth/login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/servidor',
      name: 'authServidor',
      // redirect: "manutencao",
      component: () => import('@/views/pages/auth/servidorAcesso.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/cadastroSenhaServidor',
      name: 'cadastroSenhaServidor',
      component: () => import('@/views/pages/auth/servidorCadastroSenha.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/recuperarSenhaServidor',
      name: 'recuperarSenhaServidor',
      component: () => import('@/views/pages/auth/servidorRecuperarSenha.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/assinatura-eletronica',
      name: 'Assinatura Eletrônica do Servidor',
      component: () =>
        import('@/views/pages/assinaturaEletronica/AssinaturaEletronica.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        pageTitle: 'Assinatura Eletrônica do Servidor',
        requiresAuth: false,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/nao-encontrado.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/error/nao-autorizado.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/not',
      redirect: 'not-authorized',
    },
    {
      path: '/large',
      redirect: 'too-large',
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/formCadastroServidor',
      name: 'formCadastroServidor',
      component: () => import('@/views/components/formCadastroServidor.vue'),
      meta: {
        // pageTitle: "Formulário de cadastro de servidor",
        layout: 'full',
        requiresAuth: false,
      },
    },
    {
      path: '/questionario-socioeconomico',
      name: 'formQuestionarioSocioeconomico',
      component: () =>
        import(
          '@/views/components/formQuestionarioSocioeconomico/formQuestionarioSocioeconomico.vue'
        ),
      meta: {
        // pageTitle: "Formulário de cadastro de servidor",
        layout: 'full',
        requiresAuth: false,
      },
    },
    {
      path: '/protocoloFinalizado',
      name: 'protocoloFinalizado',
      component: () => import('@/views/components/protocoloFinalizado.vue'),
      meta: {
        layout: 'full',
        requiresAuth: false,
      },
    },
    {
      path: '/protocoloAprovado',
      name: 'protocoloAprovado',
      component: () => import('@/views/components/protocoloAprovado.vue'),
      meta: {
        layout: 'full',
        requiresAuth: false,
      },
    },
    {
      path: '/protocoloCompleto',
      name: 'protocoloCompleto',
      component: () => import('@/views/components/protocoloCompleto.vue'),
      meta: {
        layout: 'full',
        requiresAuth: false,
      },
    },
    {
      path: '/protocoloEmAnalise',
      name: 'protocoloEmAnalise',
      component: () => import('@/views/components/protocoloEmAnalise.vue'),
      meta: {
        layout: 'full',
        requiresAuth: false,
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/home.vue'),
      meta: {
        pageTitle: 'Tela Inicial',
        requiresAuth: true,
      },
    },
    {
      path: '/cadastroServidor',
      name: 'cadastroServidor',
      component: () => import('@/views/pages/cadastro/servidor.vue'),
      meta: {
        layout: 'full',
        requiresAuth: false,
      },
    },
  ],
});

const isLoggedIn = isUserLoggedIn();

router.beforeEach((to, _, next) => {
  // as vezes a instancia do pinia não esta disponível
  try {
    const loadingStore = useLoadingStore();
    loadingStore.setLoadingRoute(true);
  } catch (_) {}

  if (to.meta?.requiresAuth && !canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' });

    // If logged in => not authorized"
    return next({ name: 'not-authorized' });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn && to.meta.requiresAuth) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.type : null));
  }

  if (!isLoggedIn) {
    return next();
  }

  var userData = getUserData();
  if (userData?.role == 'master') {
    return next();
  }

  // Tratativa feita pois CadastroServidor e PresencialInlocoCadastro estão com a mesma descrição de página
  const isCadInLoco =
    userData?.usuarioInloco && to.name === 'CadastroServidor' ? true : false;
  const route = isCadInLoco ? 'PresencialInlocoCadastro' : to.name;
  const requiresParametrizacao =
    isCadInLoco && to.meta?.requiresParametrizacao === true
      ? false
      : to.meta?.requiresParametrizacao;

  if (requiresParametrizacao && !hasPermissao(route)) {
    return next({ name: 'not-authorized' });
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // as vezes a instancia do pinia não esta disponível
  try {
    const loadingStore = useLoadingStore();
    loadingStore.setLoadingRoute(false);
  } catch (_) {}

  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;

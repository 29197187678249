import Vue from 'vue';
/**
 * Encapsula uma funcao com load de propriedade
 * @param {function} fn Funcao a ser encapsulada
 * @param {string / object} loadingProp Propriedade ou Objeto
 * @param {string} loading Propriedade de @loadingProp
 * @returns Funcao encapsulada
 */
export default function withLoading(
  fn,
  loadingProp = '$loading',
  loading = ''
) {
  return async (...args) => {
    /** Muda o status dependendo do tipo de loadingProp
      Se loadingProp for uma varial, muda @loadingProp
       Se loadingProp for um objeto, muda a propriedade @loading */
    const changeStatus = status =>
      typeof this[loadingProp] == 'object'
        ? (this[loadingProp][loading] = status)
        : (this[loadingProp] = status);
    changeStatus(true);
    try {
      return await fn.apply(this, args);
    } finally {
      changeStatus(false);
    }
  };
}

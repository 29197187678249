import Vue from 'vue';
import {
  BootstrapVue,
  ToastPlugin,
  ModalPlugin,
  IconsPlugin,
} from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import Toast from 'vue-toastification';
// import number from "@coders-tm/vue-number-format"
import VueMask from 'v-mask';
import LottieAnimation from 'lottie-web-vue';

Vue.use(LottieAnimation); // add lottie-animation to your global scope
import router from './router';
import VueAnalytics from 'vue-analytics';
import store from './store';
import App from './App.vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
// Global Components
import './global-components';

// 3rd party plugins
import '@/libs/acl';
import '@/libs/portal-vue';
import '@/libs/toastification';
import '@/libs/sweet-alerts';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import withLoading from './config/with-loading';
import promiseHandler from './config/promise-handler';
import * as directives from './@core/directives/diretivasGlobais';

import vSelect from 'vue-select';
// import '../mfe/RecadVue3/dist/simple-sample'

Object.keys(directives).forEach(key => {
  Vue.directive(key, directives[key]);
});

// // V-Number
// Vue.use(number, {
//   decimal: ",",
//   separator: ".",
//   //prefix: "R$ ",
//   precision: 2,
//   masked: false,
//   reverseFill: true,
//   prefill: false,
// });
// Loading Helper
Vue.prototype.$withLoading = withLoading;
Vue.prototype.$promiseHandler = promiseHandler;
//
Vue.use(VueCompositionAPI);
//
Vue.component('v-select', vSelect);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
// V-Mask
Vue.use(VueMask);
Vue.use(VueAnalytics, {
  id: `${process.env.GOOGLE_ANALYTICS}`,
  router,
  autoTracking: {
    pageviewTemplate(route) {
      return {
        page: route.path,
        name: route.name,
        title: document.title,
        location: window.location.href,
      };
    },
  },
});

// pinia store
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

const app = new Vue({
  pinia,
  router,
  store,
  render: h => h(App),
});

Vue.use(Toast);

app.$mount('#app');

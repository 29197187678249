/**
 * Atualiza o estado do elemento com base nos dados armazenados no localStorage.
 * @param {HTMLElement} element - O elemento a ser atualizado.
 */
export default function UpdateElementState(element) {
  const aprovados = JSON.parse(localStorage.getItem('aprovados')) || {};
  const reprovados = JSON.parse(localStorage.getItem('reprovados')) || {};
  if (aprovados[element.id]) {
    element.classList.remove('marcacaoReprovado');
    element.classList.add('marcacaoAprovado');

    if (element.tagName == 'INPUT') {
      const secondDiv = element;
      if (secondDiv) {
        secondDiv.classList.remove('marcacaoReprovado');
        secondDiv.classList.add('marcacaoAprovado');
      }
    }
  } else if (reprovados[element.id]) {
    element.classList.remove('marcacaoAprovado');
    element.classList.add('marcacaoReprovado');
  } else {
    if (element.tagName == 'DIV') {
      const secondDiv = element.querySelectorAll('div')[0];
      if (secondDiv) {
        secondDiv.classList.remove('marcacaoAprovado');
        secondDiv.classList.remove('marcacaoReprovado');
      }
    }
    element.classList.remove('marcacaoAprovado');
    element.classList.remove('marcacaoReprovado');
  }
}

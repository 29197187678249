export default {
  inserted(element) {
    const inputElement =
      element.tagName === 'INPUT' ? element : element.querySelector('input');

    inputElement.addEventListener('blur', function (event) {
      const value = event.target.value;
      setTimeout(() => {
        const transformedValue = value.trim().toUpperCase();
        event.target.value = transformedValue;
      }, 0);
    });
  },
  unbind(element) {
    const inputElement =
      element.tagName === 'INPUT' ? element : element.querySelector('input');
    inputElement.removeEventListener('blur', () => {});
  },
};

export function isChrome() {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edg') > -1;
  const isIOSChrome = winNav.userAgent.match('CriOS');
  const isSamsungBrowser = winNav.userAgent.match('SamsungBrowser');
  const android = navigator.userAgent.match(/Android/i);

  if (isSamsungBrowser) {
    return {
      isChrome: false,
      desktop: false,
      ios: false,
      android,
    };
  }
  if (isIOSChrome) {
    return {
      isChrome: true,
      ios: true,
      desktop: false,
      android,
    };
  } else if (
    isChromium !== null &&
    typeof isChromium !== 'undefined' &&
    vendorName === 'Google Inc.' &&
    isOpera === false &&
    isIEedge === false
  ) {
    return {
      isChrome: true,
      desktop: false,
      ios: false,
      android,
    };
  } else {
    return {
      isChrome: false,
      desktop: false,
      ios: false,
      android,
    };
  }
}

import { ref } from '@vue/composition-api';
import { defineStore } from 'pinia';

export const useLoadingStore = defineStore('loading', () => {
  const isLoadingRoute = ref(false);

  const setLoadingRoute = (value: boolean) => {
    isLoadingRoute.value = value;
  };

  return { isLoadingRoute, setLoadingRoute };
});

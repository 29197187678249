import UpdateElementState from './Update_element_state';
/**
 * Diretiva para gerenciar o estado de um elemento com base nos dados armazenados no localStorage.
 * @module stateDirective
 */
export default {
  /**
   * Chamado quando o elemento é inserido no DOM.
   * @param {HTMLElement} element - O elemento sendo inserido.
   * @param {Object} binding - O objeto de binding contendo o valor da diretiva.
   */
  inserted(element, binding, vnode) {
    vnode.context.$nextTick(() => {
      setTimeout(() => {
        UpdateElementState(element);
      }, 1000); // Atraso de 1 segundo
    });
  },

  /**
   * Chamado quando o elemento ou seus filhos são atualizados.
   * @param {HTMLElement} element - O elemento sendo atualizado.
   * @param {Object} binding - O objeto de binding contendo o valor da diretiva.
   */
  update(element, binding, vnode) {
    vnode.context.$nextTick(() => {
      setTimeout(() => {
        UpdateElementState(element);
      }, 1000); // Atraso de 1 segundo
    });
  },

  /**
   * Chamado quando a diretiva é desvinculada do elemento.
   * @param {HTMLElement} element - O elemento sendo desvinculado.
   */
  unbind(element) {
    element.classList.remove('marcacaoAprovado');
    element.classList.remove('marcacaoReprovado');
  },
};

import { hasPermissao } from '../../auth/utils';

export default {
  namespaced: true,
  state: {
    userData: localStorage.getItem('userData')
      ? JSON.parse(localStorage.getItem('userData'))
      : {},
  },
  getters: {
    userData: state => state.userData,
    hasPermissao: state => perm => {
      if (!state.userData) {
        return false;
      }
      return hasPermissao(perm, state.userData.grupoAcesso);
    },
  },
  mutations: {
    UPDATE_USER_DATA(state, val) {
      const userData = localStorage.getItem('userData');
      if (userData != null && userData != undefined)
        localStorage.removeItem('userData');

      localStorage.setItem('userData', JSON.stringify(val));
      state.userData = val;
    },
  },
  actions: {},
};
